import React, { useContext } from "react";
import AppContext from "../../../context/AppContext";
import Layout from "../../../layout/Layout";
import SEO from "../../../layout/Seo";
import ViewMedcert from "../../../components/Medcerts/ViewMedcert/ViewMedcert";
import MedcertTitle from "../../../components/Medcerts/ViewMedcert/MedcertTitle";

export default () => {
  const appContext = useContext(AppContext);
  let { activeMedcert } = appContext;

  return (
    <Layout pageTitle={<MedcertTitle activeMedcert={activeMedcert} isAdmin />}>
      <SEO title="Admin Medcerts" />
      <ViewMedcert isAdmin />
    </Layout>
  );
};
